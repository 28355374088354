import React from 'react'
import {Box, Container, Flex} from 'theme-ui'
import Reveal from '@solid-ui-components/Reveal'
import Divider from '@solid-ui-components/Divider'
import ContentText from '@solid-ui-components/ContentText'
import ContentButtons from '@solid-ui-components/ContentButtons'
import WithDefaultContent from '@solid-ui-blocks/WithDefaultContent'
import styles from './styles'

const FeaturesBlock01 = ({ content: { text, video, buttons } }) => {
  return (
      <Container>
          <Flex sx={{
              flexDirection: ['column', 'row'],
              flexWrap: 'nowrap',
              gap: '2rem',
              alignItems: 'center',
              height: ['630px', '585px']
          }}>
              {video && (
                  <Flex sx={{width: ['60%', video.width], justifyContent: 'center', flexShrink: 0}}>
                      <video loop src={video.src} autoPlay playsInline width={'100%'} style={styles.video}/>
                  </Flex>
              )}
              <Container as={Reveal}>
                  <Box sx={{textAlign: ['left', `left`]}}>
                      <ContentText content={text}/>
                  </Box>
                  {buttons && (
                      <>
                          <Divider space={3}/>
                          <ContentButtons content={buttons}/>
                      </>
                  )}
              </Container>
          </Flex>
      </Container>
  )
}

export default WithDefaultContent(FeaturesBlock01)
