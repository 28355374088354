export default {
    highlight: {
        position: `relative`,
        '.tabsComponent': {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-start',
            alignItems: 'center',
            flexWrap: 'nowrap',
            px: 4,
            margin: '0 auto',
        },
        '.tabs_tabList': {
            display: `flex`,
            flexDirection: 'column',
            justifyContent: `center`,
            m: 0,
            p: 0
        },
        '.tabs_tab': {
            height: '60px',
            variant: `text.h5`,
            fontWeight: `medium`,
            cursor: `pointer`,
            transition: `all 400ms ease`,
            color: `beta`,
            outline: `none`,
            px: '2rem',
            py: '1rem',
            borderRadius: '0.5rem',
            m: 0,
            ':not(.tabs_selectedTab):hover': {
                color: `alpha`,
                bg: `#F0F2F2`
            }
        },
        '.tabs_selectedTab': {
            bg: 'white'
        },
        '.react-tabs__tab-panel:not([class$="--selected"])': {
            display: `none`
        }
    },
    dots: {
        position: `relative`,
        '.tabs_tabList': {
            display: `flex`,
            justifyContent: `center !important`,
            m: 0,
            p: 0,
            mb: '2rem'
        },
        '.tabs_tab': {
            listStyle: `none`,
            size: `8px`,
            textIndent: `-9999px`,
            bg: `beta`,
            borderRadius: `full`,
            cursor: `pointer`,
            mx: 1,
            ':not(.tabs_selectedTab):hover': {
                bg: `omegaDarker`
            }
        },
        '.tabs_selectedTab': {
            bg: `alpha`
        }
    },
    arrowButton: {
        minWidth: `auto`,
        borderWidth: `sm`,
        borderRadius: `full`,
        boxShadow: `0 0 35px rgba(140,152,164,.425)`,
        position: `absolute`,
        top: `50%`,
        p: 3,
        mr: 2
    },
    arrowButtonRight: {
        right: 0,
        transform: `translate(-50%, 50%)`
    },
    arrowButtonLeft: {
        left: 0,
        transform: `translate(50%, 50%)`
    },
    arrow: {
        size: `10px`,
        borderBottom: `3px solid currentColor`,
        borderLeft: `3px solid currentColor`
    }
}
