import React, {Children, useContext, useEffect, useRef, useState} from 'react'
import PropTypes from 'prop-types'
import {Tab, TabList, TabPanel, Tabs} from 'react-tabs'
import {Box, Container} from 'theme-ui'
import {useResponsiveValue} from "@theme-ui/match-media";
import {VideoTabsContext} from './VideoTabsContext'
import {motion} from "framer-motion"

import styles from './styles'

const StyledTabs = ({
                        id,
                        tabs,
                        children,
                        variant,
                        autoplay,
                        autoplayInterval,
                        onChange,
                        forceRender
                    }) => {

    const {activeTab, setActiveTab} = useContext(VideoTabsContext)
    const [tabIndex, setTabIndex] = useState(0)
    const interval = useRef(null)

    const innerVariant = useResponsiveValue(['dots', 'dots', 'dots', 'dots', variant, variant])
    const dragEnabled = useResponsiveValue(['x', 'x', 'x', 'x', null])

    const selectedIndex = id ? activeTab?.index || 0 : tabIndex
    const childrenArray = Children.toArray(children)
    const totalTabs = tabs?.length || childrenArray?.length

    useEffect(() => {
        interval.current =
            autoplay &&
            setInterval(() => {
                setTabIndex(tabIndex => {
                    return tabIndex < totalTabs - 1 ? tabIndex + 1 : 0
                })
            }, autoplayInterval)
        return () => clearInterval(interval.current)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        return () =>
            id && setActiveTab(state => (state.identifier === id ? {} : state))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const handleSelect = index => {
        clearInterval(interval.current)
        id ? setActiveTab({identifier: id, index}) : setTabIndex(index)
        onChange && onChange(index)
    }

    const handleSwipe = (number) => {
        setTabIndex(tabIndex => {
            clearInterval(interval.current)
            if (tabIndex === 0 && number < 0) {
                return totalTabs - 1;
            }

            return (tabIndex + number) % totalTabs
        })
    }

    const swipeConfidenceThreshold = 10000;
    const swipePower = (offset, velocity) => {
        return Math.abs(offset) * velocity;
    };

    const customTabButtons = () =>
        tabs.filter(Boolean).map((item, index) => (
            <Tab key={`item-${index}`} className='tabs_tab'>
                {item}
            </Tab>
        ))

    const contentTabButtons = () =>
        childrenArray.map(({props}, index) =>
            innerVariant === 'dots' ? (
                    <Tab key={`item-${index}`} className='tabs_tab'/>
                ) :
                props?.content?.text?.[0] && (
                    <Tab key={`item-${index}`} className='tabs_tab'>
                        <div style={{display: 'flex', gap: '1rem'}}>
                            <p style={{margin: 0, color: 'grey'}}>
                                {String(index + 1).padStart(2, '0')}
                            </p>
                            <p style={{margin: 0}}>
                                {props.content.text[0].text}
                            </p>
                        </div>
                    </Tab>
                )
        )

    const tabButtons = (
        <TabList className='tabs_tabList' sx={{display: `flex`, flexDirection: 'column'}}>
            {tabs ? customTabButtons() : contentTabButtons()}
        </TabList>
    )

    return totalTabs ? (
        <Box sx={styles[innerVariant]}>

            <Tabs
                selectedTabClassName='tabs_selectedTab'
                selectedIndex={selectedIndex}
                onSelect={handleSelect}
                className={'tabsComponent'}
            >

                <Container sx={{
                    flexBasis: [null, 'calc(50% - 150px - 2rem)'],
                    flexShrink: 0,
                    padding: 0,
                    margin: 0,
                    display: 'flex',
                    justifyContent: ['center', 'center', 'center', 'center', 'flex-end'],
                }}>
                    {tabButtons}
                </Container>

                {childrenArray.map((item, index) => (
                    <motion.div key={`item-${index}-motion`}
                                drag={dragEnabled}
                                dragConstraints={{left: 0, right: 0}}
                                dragElastic={1}
                                onDragEnd={(e, {offset, velocity}) => {
                                    const swipe = swipePower(offset.x, velocity.x)

                                    if (swipe < -swipeConfidenceThreshold) {
                                        handleSwipe(1)
                                    } else if (swipe > swipeConfidenceThreshold) {
                                        handleSwipe(-1)
                                    }
                                }}>
                        <TabPanel key={`item-${index}`} forceRender={forceRender}>
                            {item}
                        </TabPanel>
                    </motion.div>
                ))}
            </Tabs>
        </Box>
    ) : null
}

export default StyledTabs

StyledTabs.defaultProps = {
    variant: 'highlight',
    autoplay: false,
    autoplayInterval: 12000,
    forceRender: false
}

StyledTabs.propTypes = {
    variant: PropTypes.oneOf(Object.keys(styles)),
    tabs: PropTypes.array
}
