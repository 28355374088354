import React from 'react'
import {graphql} from 'gatsby'
import {Container} from 'theme-ui'
import Layout from '@solid-ui-layout/Layout'
import Seo from '@solid-ui-components/Seo'
import Divider from '@solid-ui-components/Divider'
import ModalWithTabs from '@solid-ui-blocks/Modal/Block01'
import Header from '@solid-ui-blocks/Header/Block01'
import Hero from '@solid-ui-blocks/Hero/Block01'
import Footer from '@solid-ui-blocks/Footer/Block01'
import {normalizeBlockContentNodes} from '@blocks-helpers'
import styles from './_styles'
import Features from "@solid-ui-blocks/Features/Block02a";
import VideoTabs from "../../blocks/VideoTabs";
import VideoTabsContent from "../../blocks/VideoTabsContent";
import FeatureThree from "@solid-ui-blocks/FeaturesWithPhoto/Block03/Block03";
import Tabs from "@solid-ui-components/Tabs/Tabs";
import TabFeature from "@solid-ui-blocks/FeaturesWithPhoto/Block05/Block05";

const FunctionsPage = props => {
    const {allBlockContent} = props.data
    const content = normalizeBlockContentNodes(allBlockContent?.nodes)

    return (
        <Layout {...props}>
            <Seo title='Features'/>

            {/* Modals */}
            <ModalWithTabs content={content['contact']}/>

            {/* Blocks */}
            <Header content={content['header-light']}/>

            <Container variant='full' sx={styles.heroContainer}>
                <Hero content={content['hero']} reverse/>
            </Container>

            <Container variant='full' id={'preview'}>
                <Divider space='6'/>
                <FeatureThree content={content['tab-header']} sx={{maxWidth: '100%'}}/>
                <VideoTabs variant='highlight' autoplay>
                    <VideoTabsContent content={content['tab-feature-1']}/>
                    <VideoTabsContent content={content['tab-feature-2']}/>
                    <VideoTabsContent content={content['tab-feature-3']}/>
                    <VideoTabsContent content={content['tab-feature-4']}/>
                    <VideoTabsContent content={content['tab-feature-5']}/>
                </VideoTabs>
            </Container>

            <Divider space='6'/>
            <Container variant='full' sx={styles.featuresContainer}>
                <Features content={content['features']}/>
            </Container>

            <Divider space='6'/>
            <Container variant='wide' sx={styles.tabsContainer} id={'hardware'}>
                <FeatureThree content={content['tab-component-headline']}/>
                <Tabs variant='underline' space={0} autoplay autoplayInterval={8000}>
                    <TabFeature content={content['tab-1-terminal']}/>
                    <TabFeature content={content['tab-2-meeting-display']}/>
                </Tabs>
            </Container>

            <Divider space='5'/>
            <Footer content={content['footer']}/>

        </Layout>
    )
}

export const query = graphql`
  query goconutFunctionsBlockContent {
    allBlockContent(
      filter: { page: { in: ["goconut/features", "shared"] } }
    ) {
      nodes {
        ...BlockContent
      }
    }
  }
`

export default FunctionsPage
