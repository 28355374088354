/**
 *
 * These styles are solely for adding [background images] or
 * [background colors] to blocks.
 *
 */
import goconutOrange from '.././assets/goconutOrange.svg'
import blatt from "../assets/goconut_Palmeblatt.webp";

const functionStyles = {
    heroContainer: {
        // bg: `beta`,
        pt: [6, 7],
        pb: [5, 6],
        px: [4, 0],
        '::before': {
            content: `" "`,
            width: `100%`,
            height: ['100%', `1000px`],
            position: `absolute`,
            top: [`-25%`, `-25%`, `-25%`, `-5%`, '-15%', '-10%', '-5%'],
            right: [0, null, null, `10%`],
            transform: [`none`, null, null, `skew(15deg, 355deg) rotate(5deg)`],
            zIndex: -1,
            borderRadius: `xl`,
            background: t => `${t.colors.beta}`,
            backgroundSize: `100%`,
            backgroundPosition: `650px bottom`
        },
        '::after': {
            content: `" "`,
            position: `absolute`,
            height: '250% !important',
            top: `-15%`,
            left: `-55%`,
            transform: `scale(2)`,
            zIndex: -1,
            size: 'full',
            background: `url(${blatt}) no-repeat center center`,
            backgroundSize: `contain`,
            opacity: 0.3
        }
    },
    featuresContainer: {
        backgroundRepeat: 'space',
        width: '100vw',
        backgroundSize: '100% 100%',
        backgroundImage: `url(${goconutOrange})`,
    },
    contentTwoContainer: {
        bg: `alpha`,
        borderRadius: `xl`,
        py: [4, 5],
        px: [4, 0]
    }
}

export default functionStyles;
